import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatDialogModule,
    MatDialogRef,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MAT_DIALOG_DATA,
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CadAlertComponent } from './component/cad-alert/cad-alert.component';
import { CadAlertModule } from './component/cad-alert/cad-alert.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { PipeModule } from './pipe/pipe/pipe.module';
import { SessionService } from './service/session/session.service';
import { ModalModule } from './modal/modal/modal.module';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        NgxMaterialTimepickerModule.setLocale('pt-BR'),
        MatDialogModule,
        CadAlertModule,
        SharedDirectivesModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        //Declacoes de plugins
        NetworkInterface,
        DatePipe,
        PipeModule,
        ModalModule,
        // Declaracoes do sistema
        SessionService,
        LocalNotifications,
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        ScreenOrientation,
    ],
    bootstrap: [AppComponent],
    declarations: [AppComponent],
    entryComponents: [CadAlertComponent],
})
export class AppModule {}
