// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
};

export const apiRequest = {
    // url: env.producao  ?  "http://162.255.84.54:8080/" : "http://192.168.1.102:8080/" // Se producao colocar endereco do RASP, do crontráio do servidor

    url_rasp: 'http://10.10.10.1:8080',

    //Produção
    // url : "http://192.168.43.178"
    url: 'https://api.cadoma.com.br',

    // url_rasp : "http://10.10.10.1:8080",

    // url : "http://192.168.1.101"

    /* url: 'http://localhost', */

    //Produção
    // url : "https://app.cadoma.com.br"

    // Boa esperança
    // url: "http://app.cadoma.com.br:2243/"
    // url: "http://app.cadoma.com.br:1995/"
    // Canaa
    // url: "http://app.cadoma.com.br:1931/"
    //
    // FAM
    // url: "http://app.cadoma.com.br:5656/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
