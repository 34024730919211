import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aviso-modal',
  templateUrl: './aviso-modal.component.html',
  styleUrls: ['./aviso-modal.component.scss'],
})
export class AvisoModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
