import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmarCancelarModalComponent } from '../confirmar-cancelar-modal/confirmar-cancelar-modal.component';
import { IonicModule } from '@ionic/angular';
import { AvisoModalComponent } from '../aviso-modal/aviso-modal.component';
import { ConfirmaInformacoesParaEnvioModalComponent } from '../confirma-informacoes-para-envio-modal/confirma-informacoes-para-envio-modal.component';
import { PipeModule } from 'src/app/pipe/pipe/pipe.module';
import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material';

@NgModule({
    declarations: [
        ConfirmarCancelarModalComponent,
        ConfirmaInformacoesParaEnvioModalComponent,
        AvisoModalComponent,
    ],
    imports: [CommonModule, IonicModule, PipeModule, MatDialogModule],
    exports: [
        ConfirmarCancelarModalComponent,
        ConfirmaInformacoesParaEnvioModalComponent,
        AvisoModalComponent,
    ],
    entryComponents: [
        ConfirmarCancelarModalComponent,
        ConfirmaInformacoesParaEnvioModalComponent,
        AvisoModalComponent,
    ],
})
export class ModalModule {}
