import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {
    AlertController,
    IonSelect,
    NavController,
    Platform,
} from '@ionic/angular';
import { TipoPermissao } from './enum/tipo-permissao.enum';
import { MenuNotificacaoBean } from './model/menu-notificacao-bean';
import { MenssagemService } from './service/menssagem/menssagem.service';
import { NotificacaoService } from './service/notificacao/notificacao.service';
import { RedeService } from './service/rede/rede.service';
import { SessionService } from './service/session/session.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    public listaDeClientes: string[] = [
        'São josé',
        'Piscicultura Boa Esperanca',
        'Puro Peixe',
        'Fisher',
        'Piscicultura Helena de Troia',
        'São Pedro',
        'Piscicultura MM',
        'Peixe Bom',
        'Desenv!',
    ];
    public formGroup: FormGroup;
    public selectedIndex = 3;
    public usuarioLogado: string = '';
    public cliente: string = '';
    public isCarregado: boolean = false;
    public showComboBox: boolean = false;
    @ViewChild('selectLancamento', { static: false })
    selectLancamento: IonSelect;

    public appPages = null;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public sessionService: SessionService,
        public navController: NavController,
        private router: Router,
        public redeService: RedeService,
        public http: HttpClient,
        public notificacaoService: NotificacaoService,
        public alertController: AlertController,
        private mensagemService: MenssagemService,
        private fBuilder: FormBuilder
    ) {
        this.initializeApp();
        console.log('build app component');
    }

    async initializeApp() {
        await this.platform.ready();
        this.splashScreen.show();

        // let status bar overlay webview
        // this.statusBar.overlaysWebView(true);

        // set status bar to white
        // this.statusBar.backgroundColorByHexString('#a11418');
        try {
            this.buscarCliente();

            await this.sessionService.initSession();

            this.appPages = await this.loadMenus();

            const isConfirmarNovaSenha =
                window.location.pathname.indexOf('confirmar-nova-senha') > -1;
            if (!isConfirmarNovaSenha) {
                if (!this.sessionService.session) {
                    //Ir para tela de login
                    this.navController.navigateRoot('/login');
                    this.isCarregado = true;
                } else {
                    //Ir para o Home.
                    this.usuarioLogado = this.sessionService.session.nome;
                    this.isCarregado = true;
                    if (
                        await this.sessionService.verificarPermissao(
                            TipoPermissao.OPERADOR
                        )
                    ) {
                        // this.navController.navigateRoot('/recarga-rapida');
                        this.router.navigateByUrl('/recarga-rapida');
                        const path =
                            window.location.pathname.split('folder/')[1];
                        this.selectedIndex = this.appPages.findIndex(
                            (page) =>
                                page.url.toLowerCase() ===
                                '/recarga-rapida'.toLowerCase()
                        );
                    } else {
                        this.navController.navigateRoot('/');
                    }

                    this.buscarNotificacoes();
                }
            } else {
                // this.usuarioLogado = this.sessionService.session.nome;
                this.isCarregado = true;
            }

            setInterval(() => {
                if (this.sessionService.session) {
                    this.buscarNotificacoes();
                }
            }, 120000);
        } catch (error) {
            console.error('Erro buscar session', error);
            //Ir para tela de login
            this.navController.navigateRoot('/login');
        }

        this.splashScreen.hide();
    }

    async loadMenus() {
        this.cliente = await this.redeService.cliente();
        this.sessionService.initCliente(this.cliente);

        let carregarMenuOperador: boolean = false;
        let carregarMenuDiferenteOperador: boolean = false;

        if (this.sessionService.session) {
            for (let autorizacaoCliente of this.sessionService.session
                .autorizacoes) {
                if (
                    autorizacaoCliente.toLocaleLowerCase() ===
                    TipoPermissao.OPERADOR
                ) {
                    carregarMenuOperador = true;
                } else if (
                    autorizacaoCliente.toLocaleLowerCase() ===
                        TipoPermissao.TECNICOCADOMA ||
                    TipoPermissao.ADMINISTRADOR ||
                    TipoPermissao.ANALISTA
                ) {
                    carregarMenuDiferenteOperador = true;
                }
            }
        }

        if (carregarMenuOperador) {
            return [
                {
                    title: 'Ciclos',
                    url: '/ciclo-view',
                    icon: 'grid',
                    qtnNoticacoes: 0,
                    permissions: [TipoPermissao.OPERADOR],
                },
                {
                    title: 'Notificações',
                    url: '/notificacao-view',
                    icon: 'alert-circle',
                    permissions: [TipoPermissao.OPERADOR],
                },
                {
                    title: 'Recarga',
                    url: '/recarga-rapida',
                    icon: 'beaker',
                    permissions: [TipoPermissao.OPERADOR],
                },
                {
                    title: 'Lançamento de o2',
                    url: '/oxigenio-view',
                    icon: 'wifi',
                    permissions: [TipoPermissao.OPERADOR],
                },
                {
                    title: 'Lançamentos',
                    icon: 'clipboard',
                    items: [
                        {
                            title: 'Mortalidades',
                            url: '/pagina-mortalidade',
                        },
                        {
                            title: 'Desligamentos',
                            url: '/desligamento',
                        },
                        {
                            title: 'Taxas Extras',
                            url: '/taxa-extra',
                        },
                        {
                            title: 'Biometrias',
                            url: '/biometria',
                        },
                        {
                            title: 'Manutenção',
                            url: '/troca-de-peca-view',
                        },
                        {
                            title: 'Lançamento Peças',
                            url: '/lancamento-pecas',
                        },
                    ],
                    permissions: [TipoPermissao.OPERADOR],
                },
                {
                    title: 'Suporte Cadoma',
                    url: '/suporte-view',
                    icon: 'construct',
                    permissions: [TipoPermissao.TECNICOCADOMA],
                },
            ];
        }

        if (carregarMenuDiferenteOperador) {
            return [
                {
                    title: 'Lotes',
                    url: '/lote-view',
                    icon: 'apps',
                    permissions: [
                        TipoPermissao.ADMINISTRADOR,
                        TipoPermissao.TECNICOCADOMA,
                        TipoPermissao.ANALISTA,
                    ],
                },
                {
                    title: 'Ciclos',
                    url: '/ciclo-view',
                    icon: 'grid',
                    qtnNoticacoes: 0,
                    permissions: [
                        TipoPermissao.ADMINISTRADOR,
                        TipoPermissao.TECNICOCADOMA,
                        TipoPermissao.ANALISTA,
                        TipoPermissao.OPERADOR,
                    ],
                },
                {
                    title: 'Finalizados',
                    url: '/historicos-view',
                    icon: 'calendar',
                    permissions: [
                        TipoPermissao.ADMINISTRADOR,
                        TipoPermissao.TECNICOCADOMA,
                        TipoPermissao.ANALISTA,
                    ],
                },
                {
                    title: 'Notificações',
                    url: '/notificacao-view',
                    icon: 'alert-circle',
                    permissions: [
                        TipoPermissao.ADMINISTRADOR,
                        TipoPermissao.TECNICOCADOMA,
                        TipoPermissao.ANALISTA,
                        TipoPermissao.OPERADOR,
                    ],
                },
                {
                    title: 'Lançamentos',
                    icon: 'clipboard',
                    items: [
                        {
                            title: 'Mortalidades',
                            url: '/pagina-mortalidade',
                        },
                        {
                            title: 'Desligamentos',
                            url: '/desligamento',
                        },
                        {
                            title: 'Taxas Extras',
                            url: '/taxa-extra',
                        },
                        {
                            title: 'Recargas',
                            url: '/recarga-rapida',
                        },
                        {
                            title: 'Arraçoamentos manuais',
                            url: '/arracoamento-manual-rapido',
                        },
                        {
                            title: 'Alteração de vazão',
                            url: '/vazao-rapido',
                        },
                        {
                            title: 'Biometrias',
                            url: '/biometria',
                        },
                        {
                            title: 'Troca de peças',
                            url: '/troca-de-peca-view',
                        },
                        {
                            title: 'Lançamento de o2',
                            url: '/oxigenio-view',
                            icon: 'wifi',
                        },
                    ],
                    permissions: [
                        TipoPermissao.ADMINISTRADOR,
                        TipoPermissao.TECNICOCADOMA,
                        TipoPermissao.ANALISTA,
                    ],
                },
                // {
                //   title: 'Troca de peça',
                //   url: '/troca-de-peca-view',
                //   icon: 'build',
                //   permissions: [TipoPermissao.ADMINISTRADOR, TipoPermissao.TECNICOCADOMA, TipoPermissao.ANALISTA]
                // },
                {
                    title: 'Relatórios',
                    url: '/relatorios-view',
                    icon: 'bar-chart',
                    permissions: [
                        TipoPermissao.ADMINISTRADOR,
                        TipoPermissao.TECNICOCADOMA,
                        TipoPermissao.ANALISTA,
                    ],
                },
                // {
                //     title: 'Visão',
                //     url: '/visao-geral-piscicultura',
                //     icon: 'eye',
                //     permissions: [
                //         TipoPermissao.ADMINISTRADOR,
                //         TipoPermissao.TECNICOCADOMA,
                //         TipoPermissao.ANALISTA,
                //     ],
                // },
                {
                    title: 'Cadastros',
                    url: '/cadastros-view',
                    icon: 'create',
                    permissions: [
                        TipoPermissao.ADMINISTRADOR,
                        TipoPermissao.TECNICOCADOMA,
                        TipoPermissao.ANALISTA,
                    ],
                },
                {
                    title: 'Suporte Cadoma',
                    url: '/suporte-view',
                    icon: 'construct',
                    permissions: [TipoPermissao.TECNICOCADOMA],
                },
            ];
        }
    }

    async ngOnInit() {
        const path = window.location.pathname.split('folder/')[1];
        if (path !== undefined) {
            this.selectedIndex = this.appPages.findIndex(
                (page) => page.title.toLowerCase() === path.toLowerCase()
            );
        }
    }

    trocarInstancia(event) {
        let instancia = event.detail.value;

        if (instancia == 'São josé') {
            instancia = 5656;
        } else if (instancia == 'Fisher') {
            instancia = 5467;
        } else if (instancia == 'Piscicultura Boa Esperanca') {
            instancia = 2243;
        } else if (instancia == 'Dve2') {
            instancia = 3541;
        } else if (instancia == 'São Pedro') {
            instancia = 2659;
        } else if (instancia == 'Puro Peixe') {
            instancia = 6490;
        } else if (instancia == 'Piscicultura Helena de Troia') {
            instancia = 4456;
        } else if (instancia == 'Piscicultura MM') {
            instancia = 1995;
        } else if (instancia == 'Peixe Bom') {
            instancia = 5746;
        }

        let usuario = this.sessionService.session.email;
        let senha = this.sessionService.session.senha;

        this.redeService.login(instancia, usuario, senha);
        this.cliente = instancia;
        location.reload();
    }

    async buscarCliente() {
        this.cliente = await this.redeService.cliente();
        this.sessionService.initCliente(this.cliente);

        if (this.sessionService.session) {
            for (let autorizacaoCliente of this.sessionService.session
                .autorizacoes) {
                if (
                    autorizacaoCliente.toLocaleLowerCase() ===
                    TipoPermissao.TECNICOCADOMA
                ) {
                    this.showComboBox = true;
                }
            }
        }
        this.buildForm(this.cliente);
    }

    buildForm(cliente: string) {
        this.formGroup = this.fBuilder.group({
            cliente: [cliente, Validators.compose([Validators.required])],
        });

        this.formGroup.markAllAsTouched();
    }

    async buscarNotificacoes() {
        if (!this.sessionService.session) {
            return;
        }

        // --
        let notificacoes: MenuNotificacaoBean =
            await this.redeService.get<MenuNotificacaoBean>(
                'notificacao/verificar'
            );
        if (!notificacoes.alimentadorNotificaoes) {
            notificacoes.alimentadorNotificaoes = 0;
        }
        let index = this.appPages.findIndex(
            (page) => page.url == '/cadastros-view'
        );
        this.appPages[index].qtnNoticacoes =
            notificacoes.alimentadorNotificaoes;

        // --
        if (!notificacoes.cicloNotificacoes) {
            notificacoes.cicloNotificacoes = 0;
        }
        let indexN = this.appPages.findIndex(
            (page) => page.url == '/notificacao-view'
        );
        this.appPages[indexN].qtnNoticacoes = notificacoes.cicloNotificacoes;

        // --
        if (!notificacoes.cicloAlertas) {
            notificacoes.cicloAlertas = 0;
        }
        let indexM = this.appPages.findIndex(
            (page) => page.url == '/ciclo-view'
        );
        this.appPages[indexM].qtnNoticacoes = notificacoes.cicloAlertas;

        // --
        if (!notificacoes.historicoNotificaoes) {
            notificacoes.historicoNotificaoes = 0;
        }
        let indexH = this.appPages.findIndex(
            (page) => page.url == '/historicos-view'
        );
        this.appPages[indexH].qtnNoticacoes = notificacoes.historicoNotificaoes;

        //TODO Está muito ruim essa verificação para disparar o alerta.
        let menuAtual: MenuNotificacaoBean =
            await this.sessionService.getMenuNotificacaoBean();

        if (menuAtual) {
            //Enviar notificações, porque houve uma notificação a mais.
            let difenca: number =
                notificacoes.cicloNotificacoes +
                notificacoes.alimentadorNotificaoes +
                notificacoes.cicloAlertas -
                (menuAtual.cicloNotificacoes +
                    menuAtual.alimentadorNotificaoes);
            if (difenca > 0) {
                this.notificacaoService.notificar(
                    difenca + ' novas notificações !'
                );
            }
        } else {
            let count =
                notificacoes.cicloNotificacoes +
                notificacoes.alimentadorNotificaoes;
            if (count > 0) {
                this.notificacaoService.notificar(
                    count + ' novas notificações !'
                );
            }
        }

        this.sessionService.salvarMenuNotificacaoBean(notificacoes);
    }

    async redirect(event: CustomEvent) {
        const value = event.detail.value;
        if (value) {
            this.selectLancamento.value = null;
            this.navController.navigateForward([value]);
        }
    }

    async verificarPermissoes(permissoes: string[]) {
        return await this.sessionService.verificarPermissoes(permissoes);
    }

    onLogout() {
        this.mensagemService.confirmar(
            'Deseja realmente <strong>sair</strong> ?',
            () => {
                this.sessionService.logout();
            },
            () => {
                console.log('Confirm Cancel: blah');
            }
        );
    }
}
